.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  transition: opacity ease 0.4s;
}

.imgzoom {
  width: 233px;
  height: 233px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  object-fit: cover; 

  @media screen and (max-width: 768px) {
    height:213px;
    width:213px;
  }
  @media screen and (max-width: 500px) {
    height:204px;
    width:204px;
  }
  @media screen and (max-width: 420px) {
    height: 162px;
    width:162px;
  }
  @media screen and (max-width: 390px) {
    height: 156px;
    width:156px;
  }
  @media screen and (max-width: 370px) {
    height: 149px;
    width:149px;
  }
}

.imgzoom:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.02);
}
.show {
  visibility: visible;
  opacity: 1;
}

.show_image {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  cursor: pointer;
}
