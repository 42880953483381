::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }


  
::-webkit-scrollbar-track {
    height: 5px;
    border-radius: 10px;
  }
 ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }

  body:hover::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.5);
   
  }