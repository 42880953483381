
.hidden {
    display: none;
  }
  
  .zoomimg {
      display: block;
      width: 100vw;
      height: 100vh;
      object-fit: contain;
      cursor: pointer;
    }
    
  
  .lightbox {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  
    background: rgba(0, 0, 0, 0.5);
  
    display: flex;
    align-items: center;
    align-items: center;
  
   
    opacity: 1;
  
    transition: opacity ease 0.4s;
  }
  